import { Component, OnInit } from '@angular/core';
import { ProductsService } from 'src/app/services/products.service';
import { NotifierService } from 'src/app/services/notifier.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ZenduOne } from 'src/typings/app';
import { MatDialog } from '@angular/material';
import { PreviewEditComponent } from 'src/app/dialogs/preview-edit/preview-edit.component';
import { CancelConfirmComponent } from 'src/app/dialogs/cancel-confirm/cancel-confirm.component';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/services/auth.service';
import { FileHolder } from 'angular2-image-upload';
import { CategoryService } from 'src/app/services/category.service';

@Component({
    selector: 'app-product-edit',
    templateUrl: './product-edit.component.html',
    styleUrls: ['./product-edit.component.scss']
})
export class ProductEditComponent implements OnInit {

    public product: ZenduOne.Product;

    public isLoading: boolean;

    public uploadUrl: string;

    public headers: {};

    public dropdownSettings = {};

    public categories: Array<ZenduOne.Category>;

    public selCategories: Array<ZenduOne.Category>;

    public productTags: string[];

    /**
     * default value for SSO secret
     */
    public ssoSecret = "";

    /**
     * visibility of sso secret
     */
    public isSSOSecretVisiblle = false;

    constructor(
        private _notify: NotifierService,
        private _productsService: ProductsService,
        private _categoryService: CategoryService,
        private _dialog: MatDialog,
        private _activeRoute: ActivatedRoute,
        private _router: Router,
        private _authService: AuthService
    ) {
        this.dropdownSettings = {
            singleSelection: false,
            idField: '_id',
            textField: 'name',
            selectAllText: 'Select All',
            unSelectAllText: 'UnSelect All',
            itemsShowLimit: 3,
            allowSearchFilter: true,
            clearSearchFilter: true
        }
        this.productTags = ["Popular", "Free", "Most Popular"];
    }

    ngOnInit() {
        this.init();
    }

    private async init() {
        try {
            this.isLoading = true;

            this.categories = await this._categoryService.find();

            this.uploadUrl = `${environment.service}/cloudstrorage/upload`
            this.headers = {
                'Authorization': this._authService.getToken()
            };

            await this.update();
        }
        catch (err) {
            this._notify.error(err);
        }
        finally {
            this.isLoading = false;
        }
    }

    private async update() {
        try {
            this.isLoading = true;

            this.selCategories = [];

            let query = this._activeRoute.snapshot.queryParams;
            if (query.id) {
                this.product = await this._productsService.findOne(query.id);
                if (this.product.content.categories) {
                    this.selCategories = this.categories.filter(c => this.product.content.categories.indexOf(c._id) >= 0);
                }
                if (!this.product.mobileModules) {
                    this.product.mobileModules = [];
                }
            }
            else {
                this.product = {
                    name: "New Product",
                    content: {
                        description: "",
                        shortDescription: "",
                        attachments: [],
                        categories: [],
                        features: [],
                        benefits: [],
                        tags: [],
                        previews: [],
                        logo: "",
                    },
                    mobileModules: [],
                    type: "",
                    plans: [],
                    status: "draft"
                };
            }
        }
        catch (err) {
            this._notify.error(err);
        }
        finally {
            this.isLoading = false;
        }
    }

    public onUploadLogo(data: FileHolder) {
        if (data &&
            data.serverResponse &&
            data.serverResponse.response &&
            data.serverResponse.response.body) {
            let resp = data.serverResponse.response.body;
            if (resp.url) {
                this.product.content.logo = resp.url;
            }
            else {
                this._notify.error("Upload image failed");
            }
        }
    }

    public addMobileModule() {
        if (!this.product.mobileModules) {
            this.product.mobileModules = [];
        }

        if (this.product.mobileModules.length > 10) {
            this._notify.error("Number of mobile modules is limited to 10");
            return;
        }

        this.product.mobileModules.push({
            content: {
                logo: "",
                title: ""
            },
            ssoRedirectUrl: ""
        })
    }

    public deleteMobileModule(module: ZenduOne.ProductMobileModule) {
        const idx = this.product.mobileModules.indexOf(module);
        this.product.mobileModules.splice(idx, 1);
    }

    public onUploadLogoMobile(data: FileHolder, mobileModule: ZenduOne.ProductMobileModule) {
        if (data &&
            data.serverResponse &&
            data.serverResponse.response &&
            data.serverResponse.response.body) {
            let resp = data.serverResponse.response.body;
            if (resp.url) {
                mobileModule.content.logo = resp.url;
            }
            else {
                this._notify.error("Upload image failed");
            }
        }
    }

    public async editPreview(source?: ZenduOne.ProductPreview) {
        // open edit dialog
        let result = await this._dialog.open(PreviewEditComponent,
            { data: source ? source : null })
            .afterClosed().toPromise();

        // handle dialog result
        if (result) {
            if (!source) {
                // add a new item
                if (!this.product.content.previews) {
                    this.product.content.previews = [];
                }
                this.product.content.previews.push(result);
            }
        }
    }

    public async deletePreview(data: ZenduOne.ProductPreview) {
        try {
            let confirm = await this._dialog.open(CancelConfirmComponent, {
                width: '350px',
                data: {
                    title: "Confirm",
                    text: `Are you sure would like to delete preview "${data.name}" ?`
                }
            }).afterClosed().toPromise();

            if (!confirm) {
                return;
            }

            this.isLoading = true;

            let idx = this.product.content.previews.indexOf(data)
            this.product.content.previews.splice(idx, 1);

            await this._productsService.update(this.product);
        }
        catch (err) {
            this._notify.error(err);
        }
        finally {
            this.isLoading = false;
        }
    }

    public isImage(src: string) {
        return !this.isVideo(src);
    }

    public isVideo(src: string) {
        if (src && src.indexOf("youtube.com") >= 0) {
            return true;
        }
        return false;
    }

    public async save() {
        try {
            this.isLoading = true;

            if (this.selCategories) {
                this.product.content.categories = this.selCategories.map(c => c._id);
            }

            //  Remove empty mobile modules
            this.product.mobileModules = this.product.mobileModules.filter(mobileModule =>
                mobileModule.ssoRedirectUrl || mobileModule.content.title || mobileModule.content.logo);

            if (this.product._id) {
                await this._productsService.update(this.product);

                if (this.ssoSecret && this.ssoSecret.trim()) {
                    // update SSO secret for Auth2
                    await this._productsService.saveSSOSecret({
                        productType: this.product.type,
                        key: this.ssoSecret
                    });
                }
            }
            else {
                let data = await this._productsService.add(this.product);
                this.product._id = data.id;
                this._router.navigate(["/management/product/edit"], { queryParams: { id: data.id } });
            }
        }
        catch (err) {
            this._notify.error(err);
        }
        finally {
            this.isLoading = false;
        }
    }

}
