import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { ZenduOne } from 'src/typings/app';
import { HttpClient } from "@angular/common/http";
import { environment } from 'src/environments/environment';
import * as moment from 'moment';

@Injectable()
export class AuthService {

    private _authData: ZenduOne.AuthData;

    private _token: string;

    constructor(
        private _http: HttpClient,
        private _cookies: CookieService) {
        this._token = this._cookies.get("auth_one_console");
    }

    public async forgot() {
        this._cookies.set("auth_one_console", "", 0, "/");
    }

    public async loginWithPassword(options: {
        username: string,
        password: string
    }) {
        let data = await this.post(`auth`, options)
        if (!data || !data.token) {
            throw "auth failed"
        }

        this._token = data.token;
        this.saveToken(this._token);
    }

    public isTokenAvail(): boolean {
        return this._token ? true : false;
    }

    public async initAuth(): Promise<boolean> {
        if (!this._token) {
            return false;
        }

        this._authData = await this.post(`checkAccess`, { token: this._token });
        if (!this._authData.access) {
            return false;
        }

        return true;
    }

    /**
     * return username of logged user
     */
    public getUsername(): string {
        if (!this._authData) {
            return "";
        }
        return this._authData.username;
    }

    /**
     * get reseller name associated to user
     */
    public getResellerName(): string {
        if (!this._authData) {
            return "";
        }
        return this._authData.resellerName;
    }

    /**
    * get reseller code associated to user
    */
    public getResellerCode(): string {
        if (!this._authData) {
            return "";
        }
        return this._authData.resellerCode;
    }

    /**
    * get reseller id associated to user
    */
    public getResellerId(): string {
        if (!this._authData) {
            return "";
        }
        return this._authData.resellerId;
    }

    public isReseller(): boolean {
        if (!this._authData) {
            return false;
        }
        return this._authData.resellerName ? true : false;
    }

    public isAdmin(): boolean {
        if (!this._authData) {
            return false;
        }
        if (this._authData.resellerName) {
            return false;
        }
        if (!this._authData.permissions) {
            return false;
        }
        return this._authData.permissions.indexOf("managment.write") >= 0;
    }

    public getPermissions(): string[] {
        if (!this._authData) {
            return [];
        }
        return this._authData.permissions;
    }

    public isAgreementAccepted() {
        if (!this._authData) {
            return false;
        }
        return this._authData.agreementAccepted;
    }

    public isStripeConnectRequired() {
        if (!this._authData) {
            return false;
        }
        return this._authData.isStripeConnectRequired;
    }

    public getToken() {
        return this._token;
    }

    private saveToken(token: string) {
        // name, value, expires, path, domain, secure, sameSite
        this._cookies.set("auth_one_console", token, moment().add(2, "week").toDate(), "/", "", false, "SameSite" as any);
    }

    private async post(url: string, data?: any) {
        let res = await this._http.post(`${environment.service}/${url}`,
            data ? data : {}).toPromise()
        return res as any;
    }

    public logout(): void {
        try {
            this._cookies.delete("auth_one_console");
        }
        catch (err) {
            console.error(`Can't update cookies`)
        }

        this._token = "";
        document.location.href = "/sign-in";
    }
}
