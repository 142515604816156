import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ZenduOne } from 'src/typings/app';
import { CustomerService } from 'src/app/services/customer.service';
import { ProductsService } from 'src/app/services/products.service';
import { NotifierService } from 'src/app/services/notifier.service';
import { VehicleService } from 'src/app/services/vehicle.service';
import { MatTableDataSource, MatPaginator, MatDialog } from '@angular/material';
import { CancelConfirmComponent } from 'src/app/dialogs/cancel-confirm/cancel-confirm.component';
import { UserType } from 'src/typings/userType';
import { Middleware } from 'src/typings/middleware';
import { BillingCalculateResult, RouteBillingProduct } from 'src/typings/billing';
import { BillingService } from 'src/app/services/billing.service';

@Component({
  selector: 'app-customer-product-install',
  templateUrl: './customer-product-install.component.html',
  styleUrls: ['./customer-product-install.component.scss']
})
export class CustomerProductInstallComponent implements OnInit {

  @ViewChild("paginatorusers") paginatorUsers: MatPaginator;

  @ViewChild("paginatorvehicles") paginatorVehicles: MatPaginator;

  public isLoading: boolean;

  public isBillingLoading: boolean;

  public isInstalled: boolean;

  public isActive: boolean;

  public customer: ZenduOne.Customer;

  public product: ZenduOne.Product;

  public vehicles: Array<{
    id: string,
    name: string,
    telematic?: ZenduOne.VehicleTelematic,
    media?: ZenduOne.VehicleMedia,
    selected: boolean
  }>;

  public users: Array<{
    name: string,
    type: string,
    fullName: string,
    selected: boolean
  }>;

  public customUsername: string;

  public dataSourceVehicle = new MatTableDataSource();

  public dataSourceUsers = new MatTableDataSource();

  public billingPlan: string;

  public billingPreview: BillingCalculateResult;

  public optionalFeatures: Array<{ item: ZenduOne.ProductBillingFeature, enabled: boolean }> = [];

  public customParams: Array<{ item: Middleware.ConfigureCustomParameter, def: ZenduOne.ProductCustomParameter }> = [];

  private _timer: Object;

  private _mask = "****";

  constructor(
    private _activeRoute: ActivatedRoute,
    private _customerService: CustomerService,
    private _productService: ProductsService,
    private _notify: NotifierService,
    private _vehicleService: VehicleService,
    private _router: Router,
    private _dialog: MatDialog,
    private _billingService: BillingService
  ) { }

  ngOnInit() {
    this.billingPreview = {
      total: 0,
      lines: []
    }
    this.update();
  }


  private async update() {
    try {
      this.isLoading = true;

      let query = this._activeRoute.snapshot.queryParams;
      let customerId = query.customerId as string;
      if (!customerId) {
        throw "customerId is empty"
      }

      this.customer = await this._customerService.findOne(customerId);
      if (!this.customer) {
        throw `invalid customer id "${customerId}"`
      }

      let productId = query.id;
      if (!productId) {
        throw `invalid product id "${productId}"`
      }

      this.product = await this._productService.findOne(productId);
      if (!this.product) {
        throw `invalid product id "${productId}"`
      }

      let billings = await this._billingService.findCustomerBilling({ customerId: customerId });
      let billingProduct = billings.products.find(p => p.id == productId);

      // check installation state
      this.isInstalled = false;
      if (billingProduct) {
        this.isInstalled = true;
        this.isActive = billingProduct.active;
        this.billingPlan = billingProduct.planId;
      }
      else {
        if (this.product.plans && this.product.plans.length) {
          // set default plan
          this.billingPlan = this.product.plans[0].id;
        }
      }

      this.vehicles = [];
      this.users = [];

      await this.updateCustomParams();

      this.updateOptionalFeatures(billingProduct);

      if (this.isInstalled) {

        if (this.product.installation.users &&
          this.product.installation.users.enabled) {
          await this.loadUsers();
        }

        if (this.product.installation.vehicles &&
          this.product.installation.vehicles.enabled) {
          await this.loadVehicles();
        }
      }
      else {
        // for a new product select all vehicles

        let customerVehicles = await this._vehicleService.find({ customerId: this.customer._id });

        for (let customerVehicle of customerVehicles) {
          this.vehicles.push({
            id: customerVehicle._id,
            name: customerVehicle.name,
            telematic: customerVehicle.telematic,
            media: customerVehicle.media,
            selected: true
          });
        }

        // try load users for available platform: geotab, keeptrukin, etc...
        //
        await this.loadPlatformUsers();
      }

      this.isLoading = false;

      // update datasource after timeout
      // paginators is not exists while loading
      setTimeout(() => {
        // update table data sources
        this.updateDataSources();
      }, 100)

      // update billing
      this.updateBillingExecute();
    }
    catch (err) {
      this._notify.error(err);
    }
    finally {
      this.isLoading = false;
    }
  }

  private async updateCustomParams() {
    try {
      this.customParams = [];
      if (!this.product.installation || !this.product.installation.customParams) {
        return;
      }

      for (const defenition of this.product.installation.customParams) {
        const defaultVal = (this.isInstalled && defenition.secure) ? this._mask : "";

        this.customParams.push({
          item: { id: defenition.name, value: defaultVal },
          def: defenition
        })
      }

      if (this.isInstalled) {
        // update existing values
        const existValues = await this._customerService.getCustomParams({ id: this.customer._id, type: this.product.type });
        if (existValues && existValues.length) {
          for (const val of existValues) {
            const customParam = this.customParams.find(p => p.item.id == val.id);
            if (customParam) {
              customParam.item.value = val.value;
            }
          }
        }
      }

    }
    catch (err) {
      this._notify.error(`Failed load customer parameters: ${err}`);
    }

  }

  /**
   * load users for configuration
   */
  private async loadUsers() {
    let productUsers = new Array<Middleware.User>();
    try {
      productUsers = await this._customerService.getUsers({
        id: this.customer._id, type: this.product.type
      });
    }
    catch (err) {
      this._notify.error(`Failed load installed users for given application: ${err}`);
    }

    this.users = productUsers.map(u => ({
      name: u.name,
      type: u.type,
      fullName: `${u.firstName || ""} ${u.lastName || ""}`,
      selected: true
    }));

    // try load users for available platform: geotab, keeptrukin, etc...
    await this.loadPlatformUsers();
  }

  /**
   * try load users for available platform: geotab, keeptrukin, etc...
   */
  private async loadPlatformUsers() {
    for (let platform of this.customer.platforms) {
      if (platform.id == "standalone") {
        continue;
      }

      try {
        let platformUsers = await this._customerService.getUsers({
          id: this.customer._id, type: platform.id
        });
        if (platformUsers) {
          let existUsername = this.users.map(u => u.name);
          for (let u of platformUsers) {
            if (existUsername.indexOf(u.name) >= 0) {
              continue;
            }
            this.users.push({
              name: u.name,
              type: UserType.admin,
              fullName: `${u.firstName || ""} ${u.lastName || ""}`,
              selected: false
            })
            existUsername.push(u.name);
          }
        }
      }
      catch (err) {
        console.warn(`Failed load users for platform "${platform.id}"`);
      }
    }
  }

  /**
   * load vehicles for configuration
   */
  private async loadVehicles() {

    let productVehicles = new Array<Middleware.Device>();
    try {
      productVehicles = await this._customerService.getVehicles({
        id: this.customer._id, type: this.product.type
      });
    }
    catch (err) {
      this._notify.error(`Failed load installed vehicles for given application: ${err}`);
    }

    let customerVehicles = await this._vehicleService.find({ customerId: this.customer._id });
    this.vehicles = []

    for (let customerVehicle of customerVehicles) {
      // get device suspend status
      let isSuspend = false;

      const productVehicle = productVehicles.find(p => {
        if (customerVehicle.telematic && p.serialNumber == customerVehicle.telematic.serial) {
          return true;
        }

        if (customerVehicle.media && p.serialNumber == customerVehicle.media.serial) {
          return true;
        }

        return false;
      })

      if (!productVehicle) {
        // devices is suspended
        isSuspend = true;
      }
      else if (productVehicle && productVehicle.activeTo &&
        new Date(productVehicle.activeTo) < new Date()) {
        // devices is suspended
        isSuspend = true;
      }

      // find the vehicle from the zenduone vehicles list
      this.vehicles.push({
        id: customerVehicle._id,
        name: customerVehicle.name,
        telematic: customerVehicle.telematic,
        media: customerVehicle.media,
        selected: !isSuspend
      });
    }

    // move selected vehicles to top and sort by name
    //
    this.vehicles.sort((v1, v2) => {
      if (v1.selected && v2.selected) {
        return v1.name.localeCompare(v2.name);
      }
      if (v1.selected && !v2.selected) {
        return -1;
      }
      if (!v1.selected && v2.selected) {
        return 1;
      }
      return v1.name.localeCompare(v2.name);
    })
  }


  private updateOptionalFeatures(billingProduct: RouteBillingProduct) {
    let billingPlan = this.product.plans.find(p => p.id == this.billingPlan);
    // update optional features
    this.optionalFeatures = [];
    if (billingPlan && billingPlan.features) {
      for (let feat of billingPlan.features) {
        if (feat.optional) {
          let enabled = false;

          if (billingProduct) {
            let billFeat = billingProduct.billings.find(p => p.featureId == feat.id);
            if (billFeat) {
              enabled = billFeat.featureEnabled;
            }
          }

          this.optionalFeatures.push({
            item: feat,
            enabled: enabled
          });
        }
      }
    }
  }

  public async updateBilling() {
    clearTimeout(this._timer as number);

    this._timer = setTimeout(() => {
      this.updateBillingExecute();
      return 0;
    }, 1000) as Object;
  }

  private async updateBillingExecute() {
    try {
      this.isBillingLoading = true;

      this.billingPreview = {
        total: 0,
        lines: []
      }
      let plan = this.product.plans.find(p => p.id == this.billingPlan);
      if (!plan) {
        return;
      }

      let users = this.getConfigUsers();
      let vehicles = this.getConfigVehicels();
      let features = this.optionalFeatures.map(f => ({ id: f.item.id, enabled: f.enabled, value: f.item.value }));

      this.billingPreview = await this._billingService.calculateApp({
        billingPlan: this.billingPlan,
        productId: this.product._id,
        features: features,
        users: users,
        vehicles: vehicles
      })
    }
    catch (err) {
      this._notify.error(err);
    }
    finally {
      this.isBillingLoading = false;
    }
  }

  private updateDataSources() {
    this.dataSourceVehicle.data = this.vehicles;
    this.dataSourceVehicle.paginator = this.paginatorVehicles;

    this.dataSourceUsers.data = this.users;
    this.dataSourceUsers.paginator = this.paginatorUsers;
  }

  public toggleUsers() {
    let state = true;
    if (this.users.filter(u => u.selected).length) {
      state = false;
    }
    for (let u of this.users) {
      u.selected = state;
    }

    this.dataSourceUsers.data = this.users;

    this.updateBilling();
  }

  public toggleVehicles() {
    let state = true;
    if (this.vehicles.filter(u => u["selected"]).length) {
      state = false;
    }
    for (let u of this.vehicles) {
      u["selected"] = state;
    }

    this.dataSourceVehicle.data = this.vehicles;

    this.updateBilling();
  }

  public addCustomeUser() {
    if (!this.customUsername) {
      return;
    }

    // trim user email to avoid sync issues
    this.customUsername = this.customUsername.trim();

    this.users.splice(0, 0, {
      name: this.customUsername,
      fullName: "",
      type: UserType.admin,
      selected: true
    })

    this.customUsername = "";
    this.dataSourceUsers.data = this.users;
  }

  public async install() {
    try {

      if (!this.billingPlan) {
        throw "billing plan is not selected"
      }

      let users = this.getConfigUsers();
      let vehicles = this.getConfigVehicels();

      if (this.product.installation.users.enabled) {
        if (!users.length) {
          throw "select one or more users";
        }
      }

      const optFeaturesIds = this.optionalFeatures
        .filter(f => f.item && f.item.id && f.enabled)
        .map(f => f.item.id);

      this.isLoading = true;

      // delete masks
      for (const cParam of this.customParams) {
        if (cParam.item.value == this._mask) {
          cParam.item.value = "";
        }
      }

      await this._productService.install({
        customerId: this.customer._id,
        productId: this.product._id,
        users: users,
        vehicles: vehicles,
        billingPlan: this.billingPlan,
        optionalFeatures: optFeaturesIds,
        customParams: this.customParams.map(i => i.item)
      })

      if (!this.isInstalled) {
        this._notify.success(`App "${this.product.name}" successfuly installed`);
        // navigate back to customer
        this._router.navigate(["/management/customer/edit"], { queryParams: { id: this.customer._id } });
      }
      else {
        this.isActive = true;
        this._notify.success(`App "${this.product.name}" successfuly configured`);
      }
    }
    catch (err) {
      this._notify.error(err);
    }
    finally {
      this.isLoading = false;
    }
  }

  private getConfigVehicels() {
    let vehicles = new Array<Middleware.ConfigureVehicle>();
    if (this.product.installation.vehicles.enabled) {
      vehicles = this.vehicles.filter(v => v["selected"])
        .map(v => ({
          id: v.id,
          name: v.name,
          telematic: v.telematic,
          media: v.media
        }));
    }
    return vehicles;
  }

  private getConfigUsers() {
    let users = new Array<Middleware.ConfigureUser>();
    if (this.product.installation.users.enabled) {
      users = this.users
        .filter(u => u.selected)
        .map(u => ({ name: u.name, type: "admin", fullName: u.fullName }));
    }
    return users;
  }

  public async suspend() {
    try {
      const dialogRef = this._dialog.open(CancelConfirmComponent, {
        width: '350px',
        data: {
          title: "Confirm",
          text: `Are you sure you want to suspend this application?`
        }
      });
      let confirm = await dialogRef.afterClosed().toPromise();
      if (!confirm) {
        return;
      }

      this.isLoading = true;
      await this._productService.suspend({
        customerId: this.customer._id,
        productId: this.product._id
      });

      this._notify.success(`App "${this.product.name}" is suspend`);
      this.isActive = false;
    }
    catch (err) {
      this._notify.error(err);
    }
    finally {
      this.isLoading = false;
    }
  }

  public applyUserFilter(filterValue: string) {
    this.dataSourceUsers.filter = filterValue.trim().toLowerCase();

    if (this.dataSourceUsers.paginator) {
      this.dataSourceUsers.paginator.firstPage();
    }
  }

  public planChanged() {
    this.updateBilling();

    this.updateOptionalFeatures(null);
  }

}
