import { Component } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { ClipboardService } from 'src/app/services/clipboard.service';
import { NotifierService } from 'src/app/services/notifier.service';
import { ResellerService } from 'src/app/services/reseller.service';
import { ZenduOne } from 'src/typings/app';
import { environment } from 'src/environments/environment';
import { FileHolder } from 'angular2-image-upload';
import { CountryService } from 'src/app/services/country.service';
import { PlatformService } from 'src/app/services/platform.service';

@Component({
  selector: 'app-reseller-settings',
  templateUrl: './reseller-settings.component.html',
  styleUrls: ['./reseller-settings.component.scss']
})
export class ResellerSettingsComponent {

  public addinCode: string;

  public isLoading: boolean;

  public configuration: ZenduOne.ResellerConfiguration;

  public uploadUrl: string;

  public headers: {};

  public isStripeSetupRequired: boolean;

  public stripeClientId: string;

  public countries: Array<{ id: string, name: string }>

  public demoConfig: ZenduOne.DemoConfig = {};

  public demoCustomerId: string;

  public isDemoValid: boolean;

  constructor(
    private _authService: AuthService,
    private _clipboard: ClipboardService,
    private _notify: NotifierService,
    private _resellerService: ResellerService,
    private _countryService: CountryService,
    private _platformService: PlatformService) {

    this.init();
  }

  private async init() {
    try {
      this.isLoading = true;

      // get list of countries for country selection field
      this.countries = this._countryService.getList();

      // stripe client id used for stripe connect process
      this.stripeClientId = environment.stripeClientId;
      this.isStripeSetupRequired = this._authService.isStripeConnectRequired();

      // initalize uploader
      this.uploadUrl = `${environment.service}/cloudstrorage/upload`
      this.headers = {
        'Authorization': this._authService.getToken()
      };

      if (!this._authService.getResellerName()) {
        throw "This page is available only for reseller"
      }

      // initalize add-in code
      this.initAddInCode();

      // initalize settings
      this.configuration = await this._resellerService.findConfiguration();
      if (!this.configuration) {
        throw "Configuration is not loaded"
      }

      if (!this.configuration.address) {
        // initalize address object
        this.configuration.address = {};
      }

      const data = await this._resellerService.findDemoConfig();
      if (data) {
        this.demoConfig = data.config;
        this.demoCustomerId = data.customerId;
      } else {
        this.demoCustomerId = "";
        this.demoConfig = {};
      }

      this.isDemoValid = (this.demoConfig.database &&
        this.demoConfig.username &&
        this.demoConfig.password) ? true : false;
    }
    catch (err) {
      this._notify.error(err);
    }
    finally {
      this.isLoading = false;
    }
  }

  private initAddInCode() {
    let resellerCode = this._authService.getResellerCode();
    if (!resellerCode) {
      resellerCode = "zenduit";
    }


    this.addinCode = `{
      "name": "ZenduOne",
      "supportEmail": "support@zenduit.com",
      "version": "1.0",
      "items": [
        {
          "url": "https://one-service.zenduit.com/marketplace/addin?code=${resellerCode}",
          "icon": "https://zenduone.zenduit.com/assets/img/icon.png",
          "path": "/",
          "menuName": {
            "en": "ZenduOne"
          }
        }
      ],
      "isSigned": false
    }`;
  }

  public copyToClipboard() {
    this._clipboard.copy(this.addinCode);
    this._notify.info("Add-in code copied to clipboard")
  }

  public async save() {
    try {
      this.isLoading = true;

      await this._resellerService.updateConfiguration(this.configuration);

      this._notify.success("Configuration updated")
    } catch (err) {
      this._notify.error(err);
    }
    finally {
      this.isLoading = false;
    }
  }

  public onUploadFinished(data: FileHolder, type: string) {
    if (data &&
      data.serverResponse &&
      data.serverResponse.response &&
      data.serverResponse.response.body) {
      let resp = data.serverResponse.response.body;
      if (resp.url) {
        if (type == "loginLogo") {
          this.configuration.loginLogo = resp.url;
        }
        else if (type == "headerLogo") {
          this.configuration.headerLogo = resp.url;
        }
        else {
          console.error(`unknown type: ${type}`);
        }
      }
      else {
        this._notify.error("Upload image failed");
      }
    }
  }

  /**
   * save demo configuration
   */
  public async saveDemoDatabase() {
    try {
      this.isLoading = true;
      await this._resellerService.updateDemoConfig(this.demoConfig);

      this.isDemoValid = true;
      this._notify.success("Demo configration is successfully saved");
    }
    catch (err) {
      this._notify.error(err);
    }
    finally {
      this.isLoading = false;
    }
  }

  /**
   * login to the demo database
   */
  public loginToDemo() {
    // mock customer for login
    let loginUrl = this._platformService.getLoginUrl({
      _id: this.demoCustomerId,
      platforms: [{ id: "geotab" }]
    } as ZenduOne.Customer);
    window.open(loginUrl, "_blank")
  }
}
