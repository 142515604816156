import { Component, OnInit, Optional, Inject } from '@angular/core';
import { FileHolder } from 'angular2-image-upload';
import { ZenduOne } from 'src/typings/app';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { NotifierService } from 'src/app/services/notifier.service';
import { AuthService } from 'src/app/services/auth.service';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-product-attachment-edit',
    templateUrl: './product-attachment-edit.component.html',
    styleUrls: ['./product-attachment-edit.component.scss']
})
export class ProductAttachmentEditComponent implements OnInit {

    public item: ZenduOne.ProductAttachment;

    public uploadUrl: string;

    public headers: {};

    public isLoading: boolean;

    constructor(
        @Optional() private _taskDialog: MatDialogRef<ZenduOne.ProductAttachment>,
        @Inject(MAT_DIALOG_DATA) public data: ZenduOne.ProductAttachment,
        private _notify: NotifierService,
        private _authService: AuthService
    ) {
        this.item = data ? data : {
            name: "",
            url: ""
        };

        this.uploadUrl = `${environment.service}/cloudstrorage/upload`
        this.headers = {
            'Authorization': this._authService.getToken()
        };
    }

    ngOnInit() {
    }

    public save() {
        try {
            if (!this.item.name) {
                throw `Name is empty`
            }
            if (!this.item.url) {
                throw `Url is empty`
            }

            this._taskDialog.close(this.item);
        }
        catch (err) {
            this._notify.error(err);
        }
    }

    public onUploadFile(data: FileHolder) {
        let url = this.getUploadedUrl(data);
        if (url) {
            this.item.url = url;
        }
        else {
            this._notify.error("Upload failed");
        }
    }

    private getUploadedUrl(data: FileHolder) {
        if (data &&
            data.serverResponse &&
            data.serverResponse.response &&
            data.serverResponse.response.body) {
            let resp = data.serverResponse.response.body;
            if (resp.url) {
                return resp.url;
            }
        }
        return "";
    }
}
