import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NotifierService } from 'src/app/services/notifier.service';
import { ZenduOne } from 'src/typings/app';
import { CategoryService } from 'src/app/services/category.service';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/services/auth.service';
import { FileHolder } from 'angular2-image-upload';
import { CancelConfirmComponent } from 'src/app/dialogs/cancel-confirm/cancel-confirm.component';
import { MatDialog } from '@angular/material';

@Component({
  selector: 'app-categories-edit',
  templateUrl: './categories-edit.component.html',
  styleUrls: ['./categories-edit.component.scss']
})
export class CategoriesEditComponent implements OnInit {

  public category: ZenduOne.Category;

  public isLoading: boolean;

  public uploadUrl: string;

  public headers: {};

  constructor(
    private _notify: NotifierService,
    private _categoryService: CategoryService,
    private _activeRoute: ActivatedRoute,
    private _router: Router,
    private _authService: AuthService,
    private _dialog: MatDialog
  ) { }

  ngOnInit() {
    this.init();
  }

  private async init() {
    try {
      this.isLoading = true;

      this.uploadUrl = `${environment.service}/cloudstrorage/upload`
      this.headers = {
        'Authorization': this._authService.getToken()
      };
      await this.update();
    }
    catch (err) {
      this._notify.error(err);
    }
    finally {
      this.isLoading = false;
    }
  }

  private async update() {
    try {
      this.isLoading = true;

      let query = this._activeRoute.snapshot.queryParams;
      if (query.id) {
        this.category = await this._categoryService.findOne(query.id);
      }
      else {
        this.category = {
          name: "New Category",
          icon: ""
        };
      }
    }
    catch (err) {
      this._notify.error(err);
    }
    finally {
      this.isLoading = false;
    }
  }

  public onUploadFinished(data: FileHolder) {
    if (data &&
      data.serverResponse &&
      data.serverResponse.response &&
      data.serverResponse.response.body) {
      let resp = data.serverResponse.response.body;
      if (resp.url) {
        this.category.icon = resp.url;
      }
      else {
        this._notify.error("Upload image failed");
      }
    }
  }

  public async save() {
    try {
      this.isLoading = true;

      if (this.category._id) {
        await this._categoryService.update(this.category);
      }
      else {
        let data = await this._categoryService.add(this.category);
        this._router.navigate(["/management/category/edit"], { queryParams: { id: data.id } });
      }
    }
    catch (err) {
      this._notify.error(err);
    }
    finally {
      this.isLoading = false;
    }
  }

  public async remove() {
    try {
      const dialogRef = this._dialog.open(CancelConfirmComponent, {
        width: '350px',
        data: {
          title: "Confirm",
          text: `Are you sure you want to remove this category?`
        }
      });
      let confirm = await dialogRef.afterClosed().toPromise();
      if (!confirm) {
        return;
      }

      this.isLoading = true;

      await this._categoryService.remove(this.category._id);

      this._router.navigate(["/management/category/list"]);
    }
    catch (err) {
      this._notify.error(err);
    }
    finally {
      this.isLoading = false;
    }
  }
}
